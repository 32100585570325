module.exports = {
  defaultTitle: 'Matt Poloni',
  author: 'Matt Poloni',
  url: 'https://www.mattpoloni.com',
  givenName: 'Matt',
  familyName: 'Poloni',
  defaultDescription: 'Full Stack JavaScript Web Developer',
  socialLinks: {
    twitter: 'http://www.twitter.com/matt_poloni',
    github: 'https://www.github.com/matt-poloni',
    linkedin: 'https://www.linkedin.com/in/mattpoloni/',
  },
  googleAnalyticsID: process.env.GOOGLE_ANALYTICS,
  themeColor: '#3371B2',
  backgroundColor: '#3371B2',
  social: {
    twitter: '@matt_poloni',
  },
  recaptcha_key: process.env.SITE_RECAPTCHA_KEY,
};
